<template>
  <g>
    <svg:style>
      .cls-4, .cls-5, .cls-6, .cls-7 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#glass-pattern);
      }

      .cls-6 {
        fill: url(#glass-pattern);
      }

      .cls-7 {
        fill: url(#glass-pattern);
      }
    </svg:style>
    <linearGradient id="inox-gradient" 
      :x1="doorLeftWidth1 + 59.44" 
      :y1="doorTopHeight1 + 148.46" 
      :x2="doorLeftWidth1 + 80.38" 
      :y2="doorTopHeight1 + 148.46" 
      xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient" 
      :x1="doorLeftWidth1 + 69.91" 
      :y1="doorTopHeight1 + 117.06" 
      :x2="doorLeftWidth1 + 69.91" 
      :y2="doorTopHeight1 + 62.63" 
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient id="glass-gradient-2" data-name="glass-gradient" 
      :x1="doorLeftWidth1 + 69.91" 
      :y1="doorTopHeight1 + 175.68" 
      :x2="doorLeftWidth1 + 69.91" 
      :y2="doorTopHeight1 + 121.25" 
      xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-3" data-name="glass-gradient" 
      :x1="doorLeftWidth1 + 69.91" 
      :y1="doorTopHeight1 + 234.3" 
      :x2="doorLeftWidth1 + 69.91" 
      :y2="doorTopHeight1 + 179.86" 
      xlink:href="#glass-gradient"/>
    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>
    <g id="V16">
      <rect id="inox" v-if="showInox" data-name="inox" class="cls-4" :x="`${doorLeftWidth1 + 59.44}`" :y="`${doorTopHeight1 + 57.75}`" width="20.93" height="181.44"/>
      <polyline filter="url(#inset-shadow)" id="glass"
                class="cls-5"
                :points="`${doorLeftWidth1 + 76.19} ${doorTopHeight1 + 117.06} ${doorLeftWidth1 + 76.19} ${doorTopHeight1 + 62.63} ${doorLeftWidth1 + 63.63} ${doorTopHeight1 + 62.63} ${doorLeftWidth1 + 63.63} ${doorTopHeight1 + 117.06} ${doorLeftWidth1 + 76.19} ${doorTopHeight1 + 117.06}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-2"
                data-name="glass"
                class="cls-6"
                :points="`${doorLeftWidth1 + 76.19} ${doorTopHeight1 + 175.68} ${doorLeftWidth1 + 76.19} ${doorTopHeight1 + 121.25} ${doorLeftWidth1 + 63.63} ${doorTopHeight1 + 121.25} ${doorLeftWidth1 + 63.63} ${doorTopHeight1 + 175.68} ${doorLeftWidth1 + 76.19} ${doorTopHeight1 + 175.68}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-3"
                data-name="glass"
                class="cls-7"
                :points="`${doorLeftWidth1 + 76.19} ${doorTopHeight1 + 234.31} ${doorLeftWidth1 + 76.19} ${doorTopHeight1 + 179.86} ${doorLeftWidth1 + 63.63} ${doorTopHeight1 + 179.86} ${doorLeftWidth1 + 63.63} ${doorTopHeight1 + 234.31} ${doorLeftWidth1 + 76.19} ${doorTopHeight1 + 234.31}`"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
  }
}
</script>
